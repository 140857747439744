import AuthAxios from 'src/app/helpers/AxiosAuth';

export const WELCOME_USER_REQUEST = 'WELCOME_USER_REQUEST';
export const WELCOME_USER_SUCCESS = 'WELCOME_USER_SUCCESS';
export const WELCOME_USER_FAILURE = 'WELCOME_USER_FAILURE';
export const WELCOME_USER_DO_NOT_SHOW_CHECKED =
  'WELCOME_USER_DO_NOT_SHOW_CHECKED';

export const setIsDoNotShowChecked = (checked) => {
  return { type: WELCOME_USER_DO_NOT_SHOW_CHECKED, payload: checked };
};

export const welcomeUser = () => async (dispatch) => {
  try {
    dispatch({ type: WELCOME_USER_REQUEST });

    const response = await AuthAxios.get(
      '/applicationManagement/users/welcome',
    );

    dispatch({
      type: WELCOME_USER_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: WELCOME_USER_FAILURE,
      payload: error?.response?.data?.message || 'Error to fetch modal data',
    });
  }
};

export const sendWelcomeCoupon = async () => {
  try {
    const result = await AuthAxios.post(
      `applicationManagement/users/welcomeCoupon`,
    );
    return result.data;
  } catch (err) {
    console.error('ERROR', err);
    throw err;
  }
};
