import {
  faLocationDot as locationLight,
  faLocationPlus as locationPlusLight,
  faAddressCard as addressCardLight,
  faBooksMedical as productTypeLight,
  faUserShield as leadOwnerLight,
  faList as leadTypeLight,
  faPhone as phoneLight,
  faCalendar as calendarLight,
  faGem as gemLight,
  faEnvelope as emailLight,
  faHashtag as numberLight,
  faChevronDown as downArrowLight,
  faCheck as checkMarkLight,
  faBuilding as organizationLight,
  faUpload as uploadImageLight,
  faTally as leadVolumeLight,
  faUser as assignedToLight,
  faEye as viewLight,
  faSquareDollar as automateAndSaveLight,
  faTrashAlt as deleteLight,
  faBagShopping as checkoutLight,
  faTimes as closeLight,
  faPlus as plusLight,
  faUser as userLight,
  faEdit as editLight,
  faFilePdf as pdfLight,
  faFileCsv as csvLight,
  faFileExcel as excelLight,
  faEllipsis as moreOptionsLight,
  faPauseCircle as pauseLight,
  faCircleX as cancelLight,
  faPlayCircle as resumeLight,
  faBan as revokeLight,
  faRefresh as refreshLight,
  faUserPlus as assignUplineLight,
  faUserSlash as removeAgentLight,
  faUserCheck as pendingLight,
  faChevronLeft as leftNavigationLight,
  faChevronRight as rightNavigationLight,
  faSave as saveLight,
  faCircleArrowLeft as backLight,
  faHandHoldingDollar as handDollorLight,
  faCircleCheck as successStateLight,
  faCircleInfo as infoStateLight,
  faTriangleExclamation as warningStateLight,
  faCircleExclamation as errorStateLight,
  faCheckSquare,
  faClose,
  faArrowRightToArc,
  faCircleArrowRight as circleArrowRight,
  faArrowUpRightFromSquare as arrowUpRightFromSquareLight,
  faPaperPlane as paperPlaneLight,
  faWavePulse as lifeIcon,
  faNoteMedical as healthIcon,
  faUser as recruitmentIcon,
  faFolderXmark as archivedLight,
  faMoneyCheckPen as activeLight
} from "@fortawesome/pro-light-svg-icons";

import {
  faCircleArrowLeft as backThin,
  faFileExport as exportThin,
  faBan as revokeThin,
  faCopy as copyThin,
  faChevronUp as upArrowThin,
  faChevronDown as downArrowThin,
  faClose as closeThin,
} from "@fortawesome/pro-thin-svg-icons";

import {
  faExclamationTriangle as alertSolid,
  faEllipsis as moreOptionsSolid,
} from "@fortawesome/pro-solid-svg-icons";


export const icons = {
  locationLight,
  locationPlusLight,
  addressCardLight,
  productTypeLight,
  leadVolumeLight,
  assignedToLight,
  leadOwnerLight,
  leadTypeLight,
  phoneLight,
  calendarLight,
  gemLight,
  emailLight,
  numberLight,
  downArrowLight,
  checkMarkLight,
  organizationLight,
  backThin,
  uploadImageLight,
  viewLight,
  alertSolid,
  automateAndSaveLight,
  deleteLight,
  checkoutLight,
  closeLight,
  plusLight,
  userLight,
  editLight,
  exportThin,
  pdfLight,
  csvLight,
  excelLight,
  moreOptionsLight,
  moreOptionsSolid,
  pauseLight,
  cancelLight,
  resumeLight,
  revokeLight,
  refreshLight,
  assignUplineLight,
  removeAgentLight,
  pendingLight,
  upArrowThin,
  copyThin,
  revokeThin,
  downArrowThin,
  leftNavigationLight,
  rightNavigationLight,
  saveLight,
  backLight,
  handDollorLight,
  successStateLight,
  infoStateLight,
  warningStateLight,
  errorStateLight,
  faCheckSquare,
  faClose,
  faArrowRightToArc,
  circleArrowRight,
  closeThin,
  arrowUpRightFromSquareLight,
  paperPlaneLight,
  lifeIcon,
  healthIcon,
  recruitmentIcon,
  archivedLight,
  activeLight,
};
